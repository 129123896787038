import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Api, PAYMENT_SERVICE} from '../../api/api';
import {ModalController} from '@ionic/angular';
import Payment from '../../models/Payment';
import {environment} from '../../../environments/environment';
import {PaymentMethod} from '../../enums/PaymentMethod';
import Venue from '../../models/Venue';
import Order from '../../models/Order';
import {PaymentStatus} from '../../models/PaymentStatus';
import Customer from '../../models/Customer';
import {OrderUtils} from '../../utils/order-utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {paymentFromOrders, sleep} from 'src/smoothr-web-app-core/utils/utils';
import {AnalyticsService} from '../../services/analytics/analytics.service';
import {TranslateService} from '@ngx-translate/core';

export enum PaymentModalResult {
	SUCCESS,
	ERROR,
	DISMISS
}

// tslint:disable-next-line:prefer-const
declare var wpwlOptions: any;
// @ts-ignore
const applePayAvailable = !!window.ApplePaySession;

@Component({
	selector: 'app-payment-modal',
	templateUrl: './payment-modal.component.html',
	styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {
	payment: Payment;
	venue: Venue;
	orders: Order[];
	customer: Customer;
	loading = false;
	paymentMethods: PaymentMethod[] = [];
	selectedPaymentMethod: PaymentMethod = null;
	pm = PaymentMethod;
	vrUrl = environment.baseUrl + PAYMENT_SERVICE + 'vr/pay/';
	orderUtils = OrderUtils;
	environment = environment;
	payments = {};
	wpwlOptionsBase = {
		locale: 'de',
		style: 'plain'
	};
	forceAddressInput: false;

	constructor(
		private modalCtrl: ModalController,
		private analytics: AnalyticsService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {}

	get order(): Order {
		return this.orders[0];
	}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		orders: Order[],
		customer: Customer,
		tip: number = 0,
		forceAddressInput: boolean = false
	): Promise<{result: PaymentModalResult; error: any; payment: Payment}> {
		const paymentMethods: PaymentMethod[] = [];
		if (venue.ccEnabled) {
			paymentMethods.push(PaymentMethod.CREDIT_CARD);
		}
		if (venue.gpEnabled) {
			paymentMethods.push(PaymentMethod.GPAY);
		}
		if (venue.apEnabled && applePayAvailable) {
			paymentMethods.push(PaymentMethod.APAY);
		}
		if (venue.ppEnabled) {
			paymentMethods.push(PaymentMethod.PAYPAL);
		}
		if (venue.sbEnabled) {
			paymentMethods.push(PaymentMethod.SOFORTBANKING);
		}
		if (venue.cashEnabled) {
			paymentMethods.push(PaymentMethod.CASH);
		}
		const modal = await modalCtrl.create({
			component: PaymentModalComponent,
			componentProps: {
				payment: paymentFromOrders(orders, tip),
				paymentMethods,
				venue,
				orders,
				customer,
				forceAddressInput
			},
			backdropDismiss: false
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	ngOnInit() {
		this.analytics.paymentModalOpened();
	}

	async loadPayment() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.resetJs();
		this.payment._id = null;
		this.payment.method = this.selectedPaymentMethod;
		// reset payment container
		this.selectedPaymentMethod = null;
		wpwlOptions = null;
		this.cdr.detectChanges();
		this.selectedPaymentMethod = this.payment.method;

		try {
			switch (this.selectedPaymentMethod) {
				case PaymentMethod.PAYPAL:
					await this.loadPaypal();
					break;
				case PaymentMethod.CREDIT_CARD:
					await this.loadCreditCard();
					break;
				case PaymentMethod.SOFORTBANKING:
					await this.loadKlarna();
					break;
				case PaymentMethod.GPAY:
					await this.loadGpay();
					break;
				case PaymentMethod.APAY:
					await this.loadApay();
					break;
				case PaymentMethod.CASH:
					// await this.loadCashPay();
					break;
			}
		} catch (e) {
			console.error(e);
			this.snackBarCtrl.open(this.translate.instant('payment_modal.error'));
			this.orders.forEach(order => {
				this.analytics.paymentLoadingFailed(order, this.selectedPaymentMethod);
			});
			this.selectedPaymentMethod = null;
			this.loading = false;
			return false;
		}
		this.loading = false;
		this.orders.forEach(order => {
			this.analytics.paymentLoaded(order, this.selectedPaymentMethod);
		});
	}

	loadVr(
		paymentMethod: PaymentMethod,
		paramsBuilder: (payment: Payment) => any
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					console.log({paymentMethod, wpwl: prevPayment.wpwlOptions});
					this.payment = prevPayment;
					wpwlOptions = JSON.parse(JSON.stringify(this.payment.wpwlOptions));
					console.log(wpwlOptions);
					await this.loadVrJs(this.payment.vrPayment.checkoutId);
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				wpwlOptions = {
					...this.wpwlOptionsBase,
					...paramsBuilder(this.payment)
				};
				console.log({paymentMethod, wpwl: wpwlOptions});
				this.payment.wpwlOptions = JSON.parse(JSON.stringify(wpwlOptions));
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));
				await this.loadVrJs(this.payment.vrPayment.checkoutId);
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	}

	async loadCreditCard() {
		if (!this.venue.vrPaymentEnabled && this.venue.ppFullIntegrationEnabled) {
			await this.loadPaypalCreditCard();
		}
		await this.loadVr(PaymentMethod.CREDIT_CARD, payment => ({
			billingAddress: this.getBillingAddress(this.order, this.customer),
			mandatoryBillingFields: {
				country: true,
				state: false,
				city: true,
				postcode: true,
				street1: true
			}
		}));
	}

	async loadGpay() {
		await this.loadVr(PaymentMethod.GPAY, payment => ({
			googlePay: {
				gatewayMerchantId: payment.merchantId,
				allowedAuthMethods: ['PAN_ONLY'],
				allowedCardNetworks: ['MASTERCARD', 'VISA'],
				merchantName: this.venue.name,
				merchantId: environment.GPAY.MERCHANT_ID,
				gateway: environment.GPAY.GATEWAY,
				assuranceDetailsRequired: true
			}
		}));
	}

	async loadApay() {
		await this.loadVr(PaymentMethod.APAY, payment => ({
			applePay: {
				version: 3,
				displayName: this.venue.name,
				total: this.payment.sum,
				currencyCode: this.payment.currency,
				checkAvailability: 'canMakePayments',
				merchantIdentifier: environment.APAY_MERCHANT_ID,
				style: 'black',
				merchantCapabilities: ['supports3DS'],
				supportedNetworks: ['masterCard', 'visa'],
				shippingType: 'storePickup',
				onCancel: () => {
					console.log('onCancel');
					this.loading = false;
				},
				onPaymentAuthorized: applePayment => {
					console.log(
						'onPaymentAuthorized payment: ' + JSON.stringify(applePayment)
					);
					this.payment.status = PaymentStatus.done;
					this.orders.forEach(order => {
						this.analytics.purchase(order, this.payment);
					});
					this.modalCtrl.dismiss({
						result: PaymentModalResult.SUCCESS,
						payment: this.payment
					});
				}
			}
		}));
	}

	getBillingAddress(
		order: Order,
		customer: Customer
	): {country: string; city: string; postcode: string; street1: string} {
		if (customer && customer.street) {
			return {
				country: customer.country.toUpperCase(),
				city: customer.city,
				postcode: customer.postalCode,
				street1: customer.street + ' ' + customer.number
			};
		}
		if (this.forceAddressInput) {
			return {
				country: '',
				city: '',
				postcode: '',
				street1: ''
			};
		}
		return {
			country: order.preorder?.country?.toUpperCase(),
			city: order.preorder?.city,
			postcode: order.preorder?.postalCode,
			street1: order.preorder?.street + ' ' + order.preorder?.number
		};
	}

	resetJs() {
		const head = document.getElementsByTagName('head')[0];
		const prevVrJs = document.getElementById('vr-js');
		if (prevVrJs) {
			head.removeChild(prevVrJs);
		}
		const prevPpJs = document.getElementById('paypal-js');
		if (prevPpJs) {
			head.removeChild(prevPpJs);
		}
	}

	loadVrJs(checkoutId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			console.log('load: ' + environment.VR_URL + '?checkoutId=' + checkoutId);
			let widget = null;
			while (!widget) {
				this.cdr.detectChanges();
				await sleep(10);
				widget = document.getElementsByClassName('paymentWidgets')[0];
			}
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'vr-js';
			script.src = `${environment.VR_URL}?checkoutId=${checkoutId}`;
			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
			};
			script.onload = async () => {
				resolve();
			};
		});
	}

	loadPaypal(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.PAYPAL, 'paypal-button');
	}

	loadKlarna(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.SOFORTBANKING, 'klarna-button');
	}

	loadPaypalCreditCard(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.CREDIT_CARD, 'paypal-cc-button');
	}

	loadPaypalJs(payment: Payment): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'paypal-js';
			script.src =
				environment.PAYPAL.JS_URL +
				payment.currency +
				'&merchant-id=' +
				payment.merchantId;
			script.setAttribute(
				'data-partner-attribution-id',
				environment.PAYPAL.BN_CODE
			);
			head.appendChild(script);
			script.onerror = () => reject('Could not load script');
			script.onload = () => resolve();
		});
	}

	loadPaypalButton(
		payment: Payment,
		fundingSource: any,
		elementId: string
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			// @ts-ignore
			const button = paypal.Buttons({
				intent: 'capture',
				commit: true,
				vault: false,
				enableStandardCardFields: true,
				createOrder: (data, actions) => {
					return payment.paypal.orderId;
				},
				onApprove: data => {
					this.finishPaypal(payment, data)
						.then(() => {
							resolve();
						})
						.catch(err => {
							reject(err);
						});
				},
				onError: err => {
					this.loading = true;
					this.modalCtrl.dismiss({
						result: PaymentModalResult.ERROR,
						error: err
					});
				},
				fundingSource
			});
			if (!button.isEligible()) {
				reject('Button not eligible');
				return;
			}
			button.render('#' + elementId);
			resolve();
		});
	}

	async finishPaypal(payment: Payment, data: any) {
		this.loading = true;
		try {
			const result = await Api.finishPaypalPayment(data.orderID);
			this.orders.forEach(order => {
				this.analytics.purchase(order, result);
			});
			await this.modalCtrl.dismiss({
				result: PaymentModalResult.SUCCESS,
				payment: result
			});
		} catch (e) {
			this.orders.forEach(order => {
				this.analytics.paymentFailed(
					order,
					payment,
					'error finishing paypal ' + e.toString()
				);
			});
			this.loading = false;
			throw e;
		}
	}

	loadPaypalBase(
		paymentMethod: PaymentMethod,
		elementId: string
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					this.payment = prevPayment;
					await this.loadPaypalJs(this.payment);
					// @ts-ignore
					await this.loadPaypalButton(
						this.payment,
						this.paymentMethodToPaypalFunding(paymentMethod),
						elementId
					);
					resolve();
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));

				await this.loadPaypalJs(this.payment);
				// @ts-ignore
				await this.loadPaypalButton(
					this.payment,
					this.paymentMethodToPaypalFunding(paymentMethod),
					elementId
				);
				resolve();
			} catch (e) {
				console.error('error while paypal setup');
				reject();
			}
		});
	}

	paymentMethodToPaypalFunding(paymentMethod: PaymentMethod): any {
		switch (paymentMethod) {
			case PaymentMethod.CREDIT_CARD:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.SOFORTBANKING:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.PAYPAL:
				// @ts-ignore
				return paypal.FUNDING.PAYPAL;
			default:
				return null;
		}
	}

	dismiss() {
		if (this.loading) {
			return;
		}
		this.modalCtrl.dismiss({
			result: PaymentModalResult.DISMISS
		});
	}
	async loadCashPay() {
		console.log('Cash Payment');
		this.loading = true;
		this.payment = await Api.createPayment(this.payment);
		this.loading = false;
		this.modalCtrl.dismiss({
			result: PaymentModalResult.SUCCESS,
			payment: this.payment
		});
	}
}
