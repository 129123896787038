import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {AppComponent} from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {MapPage} from '../map/map.page';
import {TranslateService} from '@ngx-translate/core';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.doenerwerk.html',
	styleUrls: ['./home.page.doenerwerk.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/table/:tableNumber';

	environment = environment;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	languageEnum = Language;

	loading = false;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		public translate: TranslateService
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
		// this.repository.previousPreorder.emit(null);
		this.repository.verifiedOrder.emit(null);
		this.repository.address.emit(null);
		this.repository.payment.emit(null);
	}
	async locateAndShowStores() {
		this.loading = true;
		this.cdr.detectChanges();
		try {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
		this.repository.address.emit(null);
		await MapPage.navigate(this.router);
		this.loading = false;
		this.cdr.detectChanges();
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
}
