import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const doenerwerkCustomerGroup = 'doenerwerk';
export const customerGroup = doenerwerkCustomerGroup;

const supportEmail = {
	doenerwerk: 'doenerwerk@smoothr.de'
};
const firebaseConfig = {
	doenerwerk: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:8176e4acbf3694e5cbfff0',
		measurementId: 'G-ELH8QKJBMW'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyC0g6f4lPKvqu6N5h_v7nT_rPrxc9cFrsM',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	gaId: ''
};
